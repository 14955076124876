import React, { useEffect, useRef, useState } from "react"
import SectionComponent from "../../components/section"
import StandardLayout from "../../layouts/standard"
import coverImage from "../../images/about/our-mission/our-mission-cover.jpg"
import up from "../../images/downloads/up-arrow.svg"
import down from "../../images/downloads/down-arrow.svg"

export default function Investors(props) {
  const { investors } = require("../../data_file.json")

  const [sections, setSections] = useState([])
  const [current, setCurrent] = useState(null)
  const allSectionsRef = useRef(null)

  const sectionsBuffer = []
  const addInvestorSection = investor => {
    if (investor.sections && investor.sections.length > 0)
      sectionsBuffer.push(investor)
    if (investor.subItems)
      investor.subItems.map(subItem => addInvestorSection(subItem))
  }

  const investorsSortFn = (a, b) => a.order - b.order

  const InvestorListItem = props => {
    const {
      depth,
      title,
      link,
      subItems,
      id,
      parent_investor_item,
      sections,
    } = props
    const [isActive, setIsActive] = useState(false)

    const findItemWithId = (item, child_id) => {
      let result = false
      if (item.id === child_id) result = true
      else {
        if (item.subItems)
          item.subItems.forEach(subItem => {
            if (findItemWithId(subItem, child_id)) result = true
          })
      }
      return result
    }

    useEffect(() => {
      if (findItemWithId(props, current)) setIsActive(true)
    }, [])

    const RenderSubItems = ({ subItems }) => {
      console.log(subItems)
      console.log(subItems.subItems)
      return subItems.sort(investorsSortFn).map(subItem => (
        <>
          {subItem.title && (
            <li
              onClick={() => {
                setCurrent(subItem.id)
              }}
            >
              <div className={`certification-list-child`}>
                <span>{">"}</span>
                <span style={{ marginLeft: "15px" }}>{subItem.title}</span>
              </div>
            </li>
          )}
          {subItem.subItems && subItem.subItems.length > 0 ? (
            <RenderSubItems subItems={subItem.subItems} />
          ) : null}
        </>
      ))
    }

    return (
      <>
        <li style={{ cursor: "pointer" }}>
          <div
            className={`${
              isActive ? "certification-list-active" : "certification-list"
            }`}
            onClick={() => {
              // setActiveChild([-1, -1])
              // setActive(index)
              // setRenderImage(true)
              setCurrent(id)
            }}
          >
            <span>•</span>
            <span>
              {title}
            </span>
            <span style={{ marginLeft: "auto" }}>
              {isActive ? <img src={up} /> : <img src={down} />}
            </span>
          </div>

          <ul className="type-children no-marker">
            {isActive && subItems && subItems.length > 0 && (
              <RenderSubItems subItems={subItems} />
            )}
          </ul>
        </li>
        {/* <li
          style={{ marginLeft: depth * 10 + "px", marginTop: "0" }}
          className={`relative uk-tab investor ${activeAttr} ${
            titleAttr && "investor-title"
          }`}
        >
          <div className={`flex items-center justify-between ${titleAttr}`}>
            <a
              {...(link
                ? {
                    href: link,
                    target: "_blank",
                  }
                : sections && sections.length > 0
                ? {
                    onClick: () => setCurrent(id),
                  }
                : {})}
            >
              {title}
            </a>
            {link && (
              <img
                src={pdfIcon}
                className="standard-icon"
                style={{ height: "20px" }}
              />
            )}
          </div>

          {subItems && subItems.length > 0 && (
            <ul className={titleAttr ? "uk-accordion-content" : ""}>
              {subItems.sort(investorsSortFn).map(subItem => (
                <InvestorListItem depth={depth + 1} {...subItem} />
              ))}
            </ul>
          )}
        </li> */}
      </>
    )
  }

  useEffect(() => {
    investors.map(investor => addInvestorSection(investor))
    setSections(sectionsBuffer)
    setCurrent(sectionsBuffer[0].id)
  }, [])

  useEffect(() => {
    const links = allSectionsRef.current.getElementsByTagName("a")
    let i = -1
    while (++i < links.length) links[i].setAttribute("target", "_blank")
  }, [current])

  return (
    <StandardLayout title="Investors">
      <div className="cover-cont">
        <img src={coverImage} alt="" />
        <div>Investors</div>
      </div>

      <div className="catalog-head">INVESTORS</div>

      <div className="uk-container">
        <div className="uk-grid-medium" data-uk-grid>
          <div className="investor-sidebar uk-width-1-1 uk-width-auto@s">
            <ul className="uk-nav uk-nav-default" data-uk-accordion>
              {investors.sort(investorsSortFn).map(investor_item => (
                <InvestorListItem depth={0} {...investor_item} />
              ))}
            </ul>
          </div>
          <div className="uk-width-expand@s uk-width-1-1" ref={allSectionsRef}>
            {sections.map(section => (
              <div>
                {section.id === current ? (
                  <div className="w-100 mt3 mt0-ns pa4 uk-card uk-card-default">
                    <h2>{section.title}</h2>
                    {section.sections.map(sub_section => (
                      <SectionComponent {...sub_section} />
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </StandardLayout>
  )
}

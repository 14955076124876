import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import m2j from 'md-2-json';

const SectionComponent = props => {
    const { title, layout, content, sections, depth } = props;

    const sectionsContent = sections && sections.map(section =>
        <div>
            <SectionComponent {...section} depth={(depth || 0) + 1} />
        </div>
    );

    const [reports, setReports] = useState([]);
    useEffect(() => {
        if (layout == 'quarterly_reports') {
            // converting mardown to reports information
            
            const headings = m2j.parse(content);
            
            let reports = [];
            reports = Object.keys(headings).map(title => {
                let list = headings[title].raw;

                console.log(list);

                let counter = 0;
                list = list.split(`\n`).reduce((acc, line) => {
                    line = line.split('-');
                    line[1] = line.slice(1, line.length).join('-');
                    if ((line[1] || line[1] === '') && counter < 4) acc.push(line[1].trim() || '');
                    counter++;
                    return acc;
                }, []);

                return { title, list };
            });

            setReports(reports);
        }
    }, [])

    return (
        <div className={'section-component w-100' + (!depth ? ' mt4' : '')} depth={depth || 0}>
            {title && <h1>{title}</h1>}
            
            {layout !== 'quarterly_reports' ? 
                <ReactMarkdown>{content}</ReactMarkdown>
                :
                <div className="uk-grid-large uk-child-width-1-3" data-uk-grid>
                    {reports.map(report => <div>
                        <h2>{report.title}</h2>
                        <div className="uk-grid-small uk-child-width-1-2 quarterly-items mv4" data-uk-grid>
                            {report.list.map((item, index) => 
                                <a {...(item ? {
                                    href: item,
                                    target: "_blank"
                                } : {})}>
                                    <div {...(item ? {} : {
                                        className: "disabled"
                                    })}>Q{index + 1}</div>
                                </a>
                            )}
                        </div>
                    </div>)}
                </div>
            }

            {
                layout == 'grid' ?
                    <div className="uk-child-width-1-2" data-uk-grid>{sectionsContent}</div>
                : layout == 'grid_3' && sectionsContent && sectionsContent.length > 0 ?
                    <div className="uk-child-width-1-3@s uk-child-width-1-1 uk-grid uk-grid-stack" data-uk-grid>{
                        sectionsContent.map(sectionContent => <div className="grey-bg pv4" style={{ borderRight: '3px solid #fff' }}>
                            {sectionContent}
                        </div>)
                    }</div>
                :
                    <div>{sectionsContent}</div>
            }
        </div>
    )
}

export default SectionComponent;